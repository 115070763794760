import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet'; // Import Helmet
import './SubcategoriesPage.css';

const SubcategoriesPage = ({ backgroundUrl, overlay, title, description }) => {
    const { category, location } = useParams(); // Get the category name from the URL
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentSubcategory, setCurrentSubcategory] = useState(null); // State to store the current subcategory

    // Function to authenticate the user and get a token
    const authenticateUser = async () => {
        try {
            const response = await fetch('/api/Authenticate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: 0,
                    username: 'siva', // Replace with dynamic input if needed
                    password: 'kumar',
                    role: 'user',
                    scopes: 'read',
                }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('jwtToken', data.jwtToken); // Save the token
                return data.jwtToken;
            } else {
                throw new Error('Authentication failed');
            }
        } catch (err) {
            console.error('Authentication error:', err);
            setError('Authentication failed. Please try again.');
            return null;
        }
    };

    // Function to fetch subcategories
    const fetchSubcategories = async (token) => {
        try {
            const response = await axios.get(`/api/getsubcategories`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    categoryName: category,
                    cityName: location,
                },

            });
            setList(response.data?.$values || []);
            if (response.data?.$values?.length > 0) {
                setCurrentSubcategory(response.data.$values[0].subcategory); // Set the first subcategory
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
            setError('Failed to fetch subcategories. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const currentLocation = location || 'San Francisco'; // Default to 'San Francisco' if location is not provided

    useEffect(() => {
        const initialize = async () => {
            setLoading(true);
            const token = localStorage.getItem('jwtToken') || (await authenticateUser());
            if (token) {
                fetchSubcategories(token);
            } else {
                setLoading(false);
                setError('Authentication required.');
            }
        };

        initialize();
    }, [category]);

    return (
        <>
            <Helmet>
                <title>{`${category} Subcategories | LocalBizs.com `}</title>
                <meta name="description" content={`${category} subcategories page showcasing the best businesses. Find services like ${category}.`} />
                <meta name="keywords" content={`${category}, subcategories, best businesses, local businesses`} />
                <meta property="og:title" content={`${category} Subcategories | LocalBizs.com`} />
                <meta property="og:description" content={`${category} subcategories page showcasing the best businesses. Find services like ${category}.`} />
                <meta property="og:image" content={backgroundUrl} />
                <meta name="twitter:title" content={`${category} Subcategories | LocalBizs.com`} />
                <meta name="twitter:description" content={`${category} subcategories page showcasing the best businesses. Find services like ${category}.`} />
                <meta name="twitter:image" content={backgroundUrl} />
            </Helmet>
            {/* <div
                className="home-banner margin-bottom-0"
                style={{
                    background: `url(${backgroundUrl}) no-repeat center center/cover`,
                }}
                data-overlay={overlay}
            > */}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                            <div className="banner_caption text-center mb-5">
                                {/* <h1 className="banner_title ft-bold mb-1">{title}</h1> */}
                                <h1 className="banner_title ft-bold mb-1">{`${category} Subcategories | LocalBizs.com `}</h1>

                                <p className="fs-md ft-medium">
                                    Explore all the {category} services below, where you can find the best subcategories and related services
                                    that fit your needs. Each subcategory below represents a specialized area of the {category}.
                                </p>
                            </div>

                            {/* Conditional Rendering for Loading and Errors */}
                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    <p>Loading subcategories...</p>
                                </div>
                            ) : error ? (
                                <div className="text-center text-danger">
                                    <p>{error}</p>
                                </div>
                            ) : (
                                <div className="subcategory-list">
                                    {/* <h2 className="subcategory-title">{currentSubcategory || 'Subcategories'}</h2> */}
                                    <div className="row">
                                        {list.map((subcategory, index) => (
                                            <div key={index} className="col-xl-3 col-lg-4 col-md-6 mb-4">
                                                <Link
                                                    to={`/bizs_detail/${currentLocation}/${subcategory.subcategory.toLowerCase()}/${category}`}
                                                    className="subcategory-box shadow-sm rounded p-3 text-decoration-none"
                                                    aria-label={`View ${subcategory.subcategory}`}
                                                >
                                                    <div className="subcategory-icon">
                                                    <i className={`fas ${subcategory.icon || "fa-question-circle"}`}></i>                                                    </div>
                                                    <div className="subcategory-content">
                                                        <h5>{subcategory.subcategory}</h5>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </>
    );
};

export default SubcategoriesPage;