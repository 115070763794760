import React from 'react';

const NotFoundPage = ({ heading, subheading, buttonText, buttonLink, imageSrc }) => {
    return (
        <>
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                        {/* Icon */}
                        <div className="">
                            <img 
                                src={imageSrc || '/assets/img/404.png'} // Use dynamic or fallback image
                                className="img-fluid" 
                                alt="Not Found"
                            />
                        </div>
                        {/* Heading */}
                        <h1 className="mb-3 ft-bold">{heading || 'Whoops! That page doesn’t exist.'}</h1>
                        {/* Subheading */}
                        <h5 className="ft-medium fs-md mb-5">{subheading || 'The page you requested could not be found'}</h5>
                        {/* Button */}
                        <a 
                            className="btn rounded theme-bg text-light" 
                            href={buttonLink || '/'} // Dynamic or fallback link
                        >
                            {buttonText || 'Go To Home Page'}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default NotFoundPage;