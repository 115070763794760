// HomePage.js
import React from 'react';
// import HomeBannerNew from './CategoryPage';
import PopularCategories from './PopularCategories';
import FeaturedSlider from './FeaturedSlider';
import FeaturedListings from './FeaturedListings';
import TabComponent from './TabComponent';
import NewsletterForm from './NewsletterForm';
import Header from './Header';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CategoryPage from './CategoryPage';
import BestSection from './BestSection';
import { locations } from './locations';

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwtToken')); // Use token from localStorage initially
  const [currentLocation, setCurrentLocation] = useState('san francisco'); // Default location
  const navigate = useNavigate();

  const title = "Discover the Perfect Place in Your City";
  const description = "LocalBizs connects businesses with customers through easy-to-use tools, offering services like directory listings, reviews, and community engagement.";
  

  // Function to authenticate the user
  const authenticateUser = async () => {
    try {
      const response = await axios.post('/api/Authenticate', {
        username: 'siva', // Replace with dynamic input if needed
        password: 'kumar',
        role: 'user',
        scopes: 'read',
      });

      if (response.status === 200) {
        const { jwtToken } = response.data;
        setJwtToken(jwtToken);
        localStorage.setItem('jwtToken', jwtToken); // Save token in localStorage
      } else {
        throw new Error('Authentication failed');
      }
    } catch (err) {
      console.error('Authentication error:', err);
      setError(`Error during authentication: ${err.message}`);
    }
  };

  // Detect the user's location
  const detectLocation = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=8b6dd2f461e0d9`);
      const locationData = response.data.city || 'unknown';
      const matchedLocation = locations.find(loc =>
        locationData.toLowerCase().includes(loc.toLowerCase())
      );
      const finalLocation = matchedLocation || 'san francisco';
      setCurrentLocation(finalLocation); // Update location
    } catch (error) {
      console.error('Error detecting location:', error);
      setCurrentLocation('san francisco'); // Fallback to default location
    }
  };

  // Fetch categories based on the current location
  const fetchCategoriesByCity = async () => {
    let token = jwtToken || localStorage.getItem('jwtToken');
    if (!token) {
      console.warn('No token found, authenticating...');
      await authenticateUser();
      token = localStorage.getItem('jwtToken'); // Get new token after authentication
    }

    try {
      const response = await axios.get('/api/categories', {
        params: { cityName: currentLocation },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const categoryData = response.data?.$values || []; // Extract categories from $values
      setCategories(categoryData);

      // setCategories(response.data.categories);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError(err.message);
    }
  };

  // Initial authentication check and location detection
  useEffect(() => {
    if (!jwtToken) authenticateUser(); // Authenticate user if no token
    detectLocation(); // Detect location on initial load
  }, []); // Runs once on initial load

  // Fetch categories when location is updated
  useEffect(() => {
    if (currentLocation) {
      fetchCategoriesByCity();
    }
  }, [currentLocation]); // Runs whenever currentLocation changes

  // Handle dynamic location change (e.g., from a dropdown or input)
  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setCurrentLocation(newLocation); // Update location and trigger category fetch
  };

  return (
    <div>
      <Helmet>
        {/* Primary SEO Meta Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.example.com/" />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {/* <Header></Header> */}
      {/* <HomeBanner
        title={title}
        subtitle={subtitle}
        placeholder={placeholder}
        categories={categories}
        clientCounts={clientCounts}
      /> */}

      <CategoryPage
        backgroundUrl="./assets/img/banner-image.png"
        overlay="1"
        title="Find Great Place in Your Areas"
        description="Explore wonderful place to stay, salon, shoping, massage or visit local areas."
        categories={categories}
        location={currentLocation} // Passing currentLocation
      />

      {/* <PopularCategories></PopularCategories> */}

      {/* <FeaturedSlider></FeaturedSlider> */}
      <BestSection></BestSection>
      {/* <FeaturedListings></FeaturedListings> */}
      {/* <NewsletterForm></NewsletterForm> */}
      {/* <TabComponent></TabComponent> */}
      {/* <footer></footer> */}
    </div>
  );
};

export default HomePage;
