import React from 'react';

const ErrorPage = ({ errorCode, message }) => {
    return (
        <section>
            <div className="container text-center">
                <div className="">
                    <img
                        src={errorCode === 500 ? '/assets/img/500.png' : '/assets/img/404.png'}
                        alt={`${errorCode} Error`}
                        className="img-fluid"
                    />
                </div>
                <h1 className="mb-3 ft-bold">
                    {errorCode === 500 ? 'Server Error!' : 'Whoops! That page doesn’t exist.'}
                </h1>
                <h5 className="ft-medium fs-md mb-5">{message || 'An unexpected error occurred.'}</h5>
                <a className="btn rounded theme-bg text-light" href="/">Go To Home Page</a>
            </div>
        </section>
    );
};

export default ErrorPage;