import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../HomePage/Footer';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: ''
    });
    const [captchaToken, setCaptchaToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({ success: null, error: null });
    const [jwtToken, setJwtToken] = useState('');

    const captchaKey = '6LeDPIgqAAAAAAZe-XvTlVAgTDW6jMU7QjhWizrO'; // Replace with your site key

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token); // Save token
    };

    // Authenticate user and retrieve token
    const authenticateUser = async () => {
        try {
            const response = await fetch('/api/Authenticate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: 0,
                    username: 'siva',
                    password: 'kumar',
                    role: 'user',
                    scopes: 'read',
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setJwtToken(data.jwtToken); // Save token to state
                localStorage.setItem('jwtToken', data.jwtToken); // Optionally save to localStorage
            } else {
                throw new Error('Authentication failed');
            }
        } catch (err) {
            // console.error('Authentication error:', err);
            setStatus({ success: null, error: `Error during authentication: ${err.message}` });
        }
    };

    useEffect(() => {
        authenticateUser(); // Authenticate on component load
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const validateMobile = (mobile) => /^[+]?[0-9]{10,15}$/.test(mobile); // Validate mobile format

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            setStatus({ success: null, error: 'Please complete the CAPTCHA.' });
            return;
        }

        if (!formData.name || !formData.email || !formData.message || !formData.subject) {
            setStatus({ success: null, error: 'All fields except Mobile are required.' });
            return;
        }

        if (!validateEmail(formData.email)) {
            setStatus({ success: null, error: 'Please enter a valid email address.' });
            return;
        }

        if (formData.mobile && !validateMobile(formData.mobile)) {
            setStatus({ success: null, error: 'Please enter a valid mobile number.' });
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(
                '/api/contact',
                {
                    ...formData,
                    captchaToken, // Include CAPTCHA token
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`, // Include token in header
                        'Content-Type': 'application/json',
                    },
                }
            );

            setStatus({ success: 'Message sent successfully!', error: null });
            setFormData({ name: '', email: '', mobile: '', subject: '', message: '' }); // Reset form
            setCaptchaToken(''); // Reset CAPTCHA token
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
            setStatus({ success: null, error: errorMessage });
        } finally {
            setLoading(false);
            // // Frontend
            // console.log('Form Data:', formData);
            // console.log('Captcha Token:', captchaToken);
            // console.log('JWT Token:', jwtToken);
        }
    };

    return (
        <>
            <section className="gray">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="sec_title position-relative text-center">
                                <h2 className="off_title">Contact Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-start justify-content-center">
                        <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
                            <form className="row submit-form py-4 px-3 rounded bg-white mb-4" onSubmit={handleSubmit}>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Your Name *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Your Email *</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Your Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Mobile</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="+91 256 548 7542"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Subject</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type Your Subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="small text-dark ft-medium">Message *</label>
                                        <textarea
                                            className="form-control ht-80"
                                            placeholder="Your Message..."
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                                {/* Add reCAPTCHA */}
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <ReCAPTCHA
                                            sitekey={captchaKey} // Your reCAPTCHA site key
                                            onChange={handleCaptchaChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn theme-bg text-light" disabled={loading}>
                                            {loading ? (
                                                <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                            ) : (
                                                'Send Message'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>

                            {/* Display success/error messages */}
                            {status.success && (
                                <div className="alert alert-success">{status.success}</div>
                            )}
                            {status.error && (
                                <div className="alert alert-danger">{status.error}</div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;