import React, { useEffect, useState } from 'react';

// Mock fetch function to simulate API call for Terms and Conditions
const fetchTermsAndConditions = async () => {
    return {
        title: "Terms & Conditions for Localbizs",
        content: [
            {
                heading: "Introduction",
                body: [
                    "Welcome to Localbizs. By using our website (www.localbizs.com), you agree to the following terms and conditions. Please read them carefully."
                ]
            },
            {
                heading: "1. Intellectual Property",
                body: [
                    "All content, including text, graphics, and logos, is the property of Localbizs or its licensors and is protected by copyright and other intellectual property laws.",
                    "You may not reproduce, distribute, or create derivative works from our content without our explicit permission."
                ]
            },
            {
                heading: "2. User Responsibilities",
                body: [
                    "Users are expected to provide accurate and current information when registering or interacting on our site.",
                    "Users agree not to engage in activities that may harm the website or other users, including but not limited to posting offensive content, spamming, or attempting to gain unauthorized access to our systems."
                ]
            },
            {
                heading: "3. Limitation of Liability",
                body: [
                    "Localbizs will not be liable for any damages resulting from the use of our website, including indirect or consequential losses.",
                    "We provide our services 'as is' without warranties of any kind."
                ]
            },
            {
                heading: "4. Changes to Terms",
                body: [
                    "Localbizs reserves the right to modify these terms at any time. Continued use of the site signifies acceptance of any updates to these terms.",
                    "It is your responsibility to periodically review this page for changes."
                ]
            },
            {
                heading: "5. Governing Law",
                body: [
                    "These terms are governed by and construed in accordance with the laws of the jurisdiction in which Localbizs operates. Disputes will be resolved in the courts of that jurisdiction."
                ]
            },
            {
                heading: "6. Contact Information",
                body: [
                    "For questions regarding these Terms & Conditions, please contact us at support@localbizs.com."
                ]
            }
        ]
    };
};

const TermsAndConditions = () => {
    const [termsData, setTermsData] = useState({ title: "", content: [] });

    useEffect(() => {
        const getTermsData = async () => {
            const data = await fetchTermsAndConditions();
            setTermsData(data);
        };
        getTermsData();
    }, []);

    return (
        <div id="main-wrapper">
            <section className="middle">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                            <div className="abt_caption">
                                <br></br>
                                <h2 className="ft-medium mb-4">{termsData.title}</h2>
                                {termsData.content.map((section, index) => (
                                    <div key={index}>
                                        <h3 className="ft-medium fs-md mb-3">{section.heading}</h3>
                                        {section.body.map((paragraph, i) => (
                                            <p className="mb-4" key={i}>{paragraph}</p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsAndConditions;