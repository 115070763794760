import React from 'react';

const Footer = () => {
    return (
        <footer className="light-footer skin-light-footer style-2">
            <div className="footer-middle py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="container">
                    <div className="row">
                        {/* Logo and Contact Info */}
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <img
                                    src="/assets/img/logo.png"
                                    className="img-footer small mb-3"
                                    alt="Company Logo"
                                    style={{ width: '150px' }}
                                />
                                <p>
                                    Find Your Local Experts, <br />
                                    Anytime, Anywhere <br />
                                    <strong>LocalBizs.com</strong>
                                </p>
                                <div className="contact-info">
                                    <p>
                                        <strong>Phone:</strong>{' '}
                                        <a href="tel:ComingSoon" className="text-muted">
                                            Coming Soon
                                        </a>
                                    </p>
                                    <p>
                                        <strong>Email:</strong>{' '}
                                        <a href="mailto:support@LocalBizs.com" className="text-muted">
                                            support@LocalBizs.com
                                        </a>
                                    </p>
                                </div>
                                <div className="social-links mt-3">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a href="#" className="theme-cl" aria-label="Facebook">
                                                <i className="lni lni-facebook-filled"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" className="theme-cl" aria-label="Twitter">
                                                <i className="lni lni-twitter-filled"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" className="theme-cl" aria-label="YouTube">
                                                <i className="lni lni-youtube"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" className="theme-cl" aria-label="Instagram">
                                                <i className="lni lni-instagram-filled"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#" className="theme-cl" aria-label="LinkedIn">
                                                <i className="lni lni-linkedin-original"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Trustpilot Section */}
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">Trustpilot</h4>
                                <div className="trustpilot-widget" data-locale="en-US">
                                    <a
                                        href="https://www.trustpilot.com/review/localbizs.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-muted"
                                    >
                                        See our reviews
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Helpful Topics */}
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">Helpful Topics</h4>
                                <ul className="footer-menu list-unstyled">
                                    <li>
                                        <a href="/terms-conditions" className="text-muted">
                                            Terms & Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/about-us" className="text-muted">
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/contact-us" className="text-muted">
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy" className="text-muted">
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* Additional Section for Future Content */}
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">Explore</h4>
                                <ul className="footer-menu list-unstyled">
                                    <li>
                                        <a href="#" className="text-muted">
                                            Careers
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-muted">
                                            Blog
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-muted">
                                            FAQ
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-muted">
                                            Partnerships
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="footer-bottom py-3" style={{ backgroundColor: '#e9ecef' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p className="mb-0">
                                &copy; {new Date().getFullYear()}{' '}
                                <a
                                    href="https://www.LocalBizs.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-primary"
                                >
                                    LocalBizs.com
                                </a>
                                . All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;