import React from 'react';

// Sample data for amenities
// const amenitiesData = [
//   { name: "Health Score 8.7 / 10", isActive: true },
//   { name: "Offers Delivery", isActive: true },
//   { name: "Offers Takeout", isActive: true },
//   { name: "Reservations", isActive: true },
//   { name: "Staff wears masks", isActive: true },
//   { name: "Vegan Options", isActive: true },
//   { name: "Vegetarian Options", isActive: true },
//   { name: "Accepts Credit Cards", isActive: true },
//   { name: "Casual", isActive: true },
//   { name: "Moderate Noise", isActive: true },
//   { name: "Offers Catering", isActive: true },
//   { name: "Good for Groups", isActive: true },
//   { name: "Good For Kids", isActive: true },
//   { name: "Good for Breakfast", isActive: true },
//   { name: "Brunch, Lunch, Dinner", isActive: true },
//   { name: "Private Lot Parking", isActive: true },
//   { name: "Waiter Service", isActive: true },
//   { name: "Free Wi-Fi", isActive: true },
//   { name: "Beer & Wine", isActive: true },
//   { name: "Drive-Thru", isActive: true },
//   { name: "Wheelchair Accessible", isActive: false },
//   { name: "TV Services", isActive: false },
//   { name: "Outdoor Seating", isActive: false },
//   { name: "Happy Hour", isActive: false },
//   { name: "Pets Allow", isActive: false }
// ];

const AmenitiesAndMore = ({ amenities }) => {
  return (
    <div className="d-block">
      <div className="jbd-01">
        <div className="jbd-details">
          <h5 className="ft-bold fs-lg">Amenities and More</h5>

          <div className="Goodup-all-features-list mt-3">
            <ul>
              {amenities.map((amenity, index) => (
                <li key={index}>
                  <div
                    className={`Goodup-afl-pace ${amenity.isActive ? "" : "active"
                      }`}
                  >
                    <img
                      src="/assets/img/verify.svg"
                      className="img-fluid"
                      alt={amenity}
                    />
                    <span>{amenity}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmenitiesAndMore;