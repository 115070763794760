import React, { useState } from 'react';

const OrderForm = ({ deliveryFee, minOrder, deliveryTime }) => {
    const [address, setAddress] = useState('');

    const handleAddressChange = (e) => setAddress(e.target.value);

    const handleOrderStart = () => {
        if (address) {
        //    alert(`Order started for: ${address}`);
        } else {
        //    alert('Please enter a delivery address.');
        }
    };

    return (
        <div className="jb-apply-form bg-white rounded py-4 px-4 mb-4">
            <h4 className="ft-bold mb-1">Order Food</h4>

            <div className="Goodup-09kjh">
                <ul>
                    <li>
                        ${deliveryFee}+<span>delivery fee</span>
                    </li>
                    <li>
                        ${minOrder}<span>min</span>
                    </li>
                    <li>
                        {deliveryTime}<span>mins</span>
                    </li>
                </ul>
            </div>

            <form className="_apply_form_form" onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <div className="side-search-item">
                        <span className="search-tag">
                            <i className="lni lni-map-marker"></i>
                        </span>
                        <input
                            type="text"
                            className="form-control b-0 ps-2"
                            placeholder="Enter delivery address"
                            value={address}
                            onChange={handleAddressChange}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <button
                        type="button"
                        className="btn btn-md rounded theme-bg text-light ft-medium fs-sm full-width"
                        onClick={handleOrderStart}
                    >
                        Start Your Order
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OrderForm;