import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if the user has already given consent
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === null) {
      setShowBanner(true); // Show banner if not previously accepted
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false); // Hide the banner
  };

  if (!showBanner) return null; // Do not render the banner if the user has already interacted

  return (
    <div style={styles.banner}>
      <p>
        We use cookies to improve your experience. By using our website, you agree to our use of cookies. 
        <a href="/privacy-policy" style={styles.link}>Learn more</a>.
      </p>
      <button onClick={handleAcceptCookies} style={styles.button}>Accept</button>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    backgroundColor: '#2c3e50', // A dark shade for better contrast
    color: '#ecf0f1', // A light color for text
    padding: '15px',
    textAlign: 'center',
    fontSize: '16px',
    zIndex: '1000',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)', // Adds a subtle shadow
  },
  button: {
    backgroundColor: '#27ae60', // A green color for a positive action
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    marginLeft: '10px',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
  link: {
    color: '#3498db', // A blue shade for the link
    textDecoration: 'underline',
    marginLeft: '5px',
    transition: 'color 0.3s ease',
  },
};

// Add hover effects
styles.button[':hover'] = {
  backgroundColor: '#1e8449', // Darker green on hover
};

styles.link[':hover'] = {
  color: '#2980b9', // Darker blue on hover
};

export default CookieBanner;