import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './AddBusiness.css'; // Ensure this file is linked

const AddBusiness = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        website: '',
        category: '',
        description: '',
    });
    const [captchaToken, setRecaptchaToken] = useState(null);
    const [jwtToken, setJwtToken] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const authenticateUser = async () => {
        try {
            const response = await fetch('/api/Authenticate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: 0,
                    username: 'siva',
                    password: 'kumar',
                    role: 'user',
                    scopes: 'read',
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setJwtToken(data.jwtToken);
                localStorage.setItem('jwtToken', data.jwtToken);
            } else {
                setError('Authentication failed. Please try again.');
            }
        } catch (err) {
            setError(`Error during authentication: ${err.message}`);
        }
    };

    useEffect(() => {
        authenticateUser();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setSuccessMessage('');

        if (!captchaToken) {
            setError('Please complete the reCAPTCHA to proceed.');
            return;
        }

        if (!jwtToken) {
            setError('Authentication token is missing. Please refresh the page and try again.');
            return;
        }

        try {
            const response = await axios.post(
                '/api/bizsaddbusiness',
                { ...formData, captchaToken },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setSuccessMessage('Business added successfully!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    website: '',
                    category: '',
                    description: '',
                });
                setRecaptchaToken(null); // Reset reCAPTCHA token
            } else {
                setError('Failed to add business. Please try again.');
            }
        } catch (err) {
            console.error('Error adding business:', err);
            setError(`An error occurred: ${err.response?.data?.message || err.message}`);
        }
    };

    return (
        <div className="container my-5 add-business">
            <h2 className="text-center mb-4">Add your Business for Lisitings   </h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label>Business Name</label>
                    <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Phone</label>
                    <input
                        type="tel"
                        name="phone"
                        className="form-control"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Address</label>
                    <input
                        type="text"
                        name="address"
                        className="form-control"
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Website</label>
                    <input
                        type="url"
                        name="website"
                        className="form-control"
                        value={formData.website}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Category</label>
                    <input
                        type="text"
                        name="category"
                        className="form-control"
                        value={formData.category}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Description</label>
                    <textarea
                        name="description"
                        className="form-control"
                        rows="4"
                        value={formData.description}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="form-group my-4">
                    <ReCAPTCHA
                        sitekey="6LeDPIgqAAAAAAZe-XvTlVAgTDW6jMU7QjhWizrO"
                        onChange={handleRecaptchaChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                    Add Business
                </button>
            </form>
        </div>
    );
};

export default AddBusiness;