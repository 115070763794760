import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { locations } from './locations';


const DynamicSearchForm = () => {
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  const [error, setError] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  // Handle user authentication
  const authenticateUser = async () => {
    try {
      const response = await fetch('/api/Authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: 0,
          username: 'siva', // Replace with dynamic input if needed
          password: 'kumar',
          role: 'user',
          scopes: 'read',
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('jwtToken', data.jwtToken);
        return data.jwtToken;
      } else {
        throw new Error(`Authentication failed: ${response.status}`);
      }
    } catch (err) {
      console.error('Authentication error:', err);
      setError('Error during authentication.');
    }
  };

  // Detect user's location based on IP
  const detectLocation = async () => {
    try {
      const response = await axios.get('https://ipinfo.io/json?token=8b6dd2f461e0d9');
      const locationData = response.data.city || 'unknown';
      setCurrentLocation(locationData);
      setLocation(locationData); // Set detected location to input
    } catch (error) {
      console.error('Error detecting location:', error);
      setLocation('San Francisco'); // Fallback to a default location
    }
  };

  // Fetch autocomplete suggestions
  const fetchSuggestions = async (query) => {
    let jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.warn('No token found, authenticating...');
      await authenticateUser(); // Authenticate and fetch a new token
      jwtToken = localStorage.getItem('jwtToken');
    }

    try {
      const response = await axios.get(`/api/bizsautocomplete?query=${query}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      // setSuggestions(response.data?.$values || []);

      const suggestions = response.data?.$values || [];
      setSuggestions(suggestions.map(suggestion => ({
        businessName: suggestion.businessName,
        categoryUrl: suggestion.categoryUrl
      })));
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized. Attempting re-authentication...');
        await authenticateUser(); // Retry authentication
      }
    }
  };


  // Highlight matched portion in suggestions
  const highlightMatch = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight">{part}</span>
      ) : (
        part
      )
    );
  };

  // Handle input change
  const handleChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      fetchSuggestions(query);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.businessName);
    setShowSuggestions(false);
  };

  // Handle search
  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      const formattedQuery = searchQuery.toLowerCase().replace(/\s+/g, '-');
      const matchingLocation = locations.find((loc) =>
        searchQuery.toLowerCase().includes(loc)
      );
      const locationForURL = matchingLocation || currentLocation;
      const categoryForURL = suggestions.find(suggestion => suggestion.businessName === searchQuery)?.categoryUrl || '';
      // Construct the final query with categoryUrl
   

      const cleanQuery = matchingLocation
        ? formattedQuery.replace(`-${matchingLocation}`, '')
        : formattedQuery;
        const finalQuery = categoryForURL
        ? `${cleanQuery}/${categoryForURL.toLowerCase()}`
        : cleanQuery;
      console.log(`Navigating to: /search/${locationForURL}/${cleanQuery}`);
      navigate(`/bizs_detail/${locationForURL}/${finalQuery}`);
    }
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value); // Set the new location manually
  };
  // Initialize location detection and authentication on component mount
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      authenticateUser();
    }
    detectLocation();
  }, []);

  return (
    <form className="main-search-wrap fl-wrap half-column">
      <div className="main-search-item">
        <span className="search-tag">Find</span>
        <input
          type="text"
          className="form-control radius"
          placeholder="Nail salons, plumbers, takeout..."
          value={searchQuery}
          onChange={handleChange}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
          onFocus={() => searchQuery.length > 2 && setShowSuggestions(true)}
        />
        {showSuggestions && suggestions.length > 0 && (
          <ul className="autocomplete-suggestions">
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                <i className="fas fa-search"></i> {' '}
                {highlightMatch(suggestion.businessName, searchQuery)}
                {/* You can optionally display the categoryUrl */}
                {/* <small>{suggestion.categoryUrl}</small> */}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="main-search-item">
        <span className="search-tag">Where</span>
        <input
          type="text"
          className="form-control"
          placeholder="San Francisco, CA"
          value={location || currentLocation}
          onChange={handleLocationChange} // Allow manual location input
        />
      </div>

      <div className="main-search-button">
        <button className="btn full-width theme-bg text-white" type="button" onClick={handleSearch}
        >
          Search <i className="fas fa-search"></i>
        </button>
      </div>
    </form>
  );
};

export default DynamicSearchForm;