import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/HomePage/Header'; // For homepage
import DarkHeader from './components/HomePage/DarkHeader'; // For other pages
import Footer from './components/HomePage/Footer'; // Common Footer
import HomePage from './components/HomePage/HomePage';
import BusinessListing from './components/ListingPage/BusinessListing';
import SingleListingTemplate from './components/SinglePage/SingleListingTemplate';
import ContactUs from './components/Menu/Contact';
import AboutUs from './components/Menu/AboutUs';
import PrivacyPolicy from './components/Menu/PrivacyPolicy';
import TermsAndConditions from './components/Menu/TermsAndConditions';
import NotFoundPage from './components/Menu/NotFoundPage';
import CookieBanner from './components/Menu/CookieBanner';
import AddBusiness from './components/HomePage/AddBusiness';
import HomeBannerNew from './components/HomePage/CategoryPage';
import CategoriesHomeBanner from './components/HomePage/SubcategoriesPage';
import SubcategoriesPage from './components/HomePage/SubcategoriesPage';
import React, { useEffect } from 'react';

const isTokenExpired = (token) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  const expiry = payload.exp * 1000; // Convert to milliseconds
  return Date.now() > expiry;
};

const checkTokenOnLoad = () => {
  const token = localStorage.getItem('jwtToken');
  if (!token || isTokenExpired(token)) {
      console.warn("Token expired. Redirecting to login.");
      window.location.href = '/contact-us'; // Redirect to login page
  }
};

const App = () => {
  
  const location = useLocation();

  // Function to determine if it's the homepage
  const isHomePage = location.pathname === '/';
//   useEffect(() => {
//     checkTokenOnLoad(); // Runs on app load
// }, []);

  return (
    <>
      {/* Conditional Header */}
      {/* {isHomePage ? <Header /> : <DarkHeader />} */}
            {isHomePage ? <Header />:<Header /> }

      
      {/* Cookie Banner (if applicable) */}
      <CookieBanner />
      {/* {!isContactUsPage && <DynamicSearchForm />}  Only render if it's not the Contact Us page */}

      {/* Routes for the application */}
      <Routes>
        {/* Home Page */}
        <Route path="/" element={<HomePage />} />

        {/* Business Listing Page */}
        <Route path="/bizs_detail/:location/:query/:category" element={<BusinessListing />} />

        {/* Single Listing Page */}
        <Route path="/bizs/:location/:name/:businessDetailID" element={<SingleListingTemplate />} />

        {/* Static Pages */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/add-business" element={<AddBusiness />} />
        {/* <Route path="/desc_find/:category" element={<CategoriesHomeBanner/>} /> */}

        <Route path="/categories/:category/:location" element={<SubcategoriesPage />} />

        {/* Not Found Page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {/* Common Footer */}
      <Footer />
    </>
  );
};

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;