import React from 'react';
import Slider from 'react-slick'; // Ensure slick-carousel is installed
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FeaturedSlider = ({ slider }) => {
  const { sliderData = [] } = slider;

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller desktops
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="featured-slider">
      <Slider {...settings}>
        {sliderData.map((item, index) => (
          <div key={index} className="slider-item">
            <a href={item.url} className="slider-link">
              <img
                src={item.url}
                alt={item.alt || `Slide ${index + 1}`}
                className="slider-image"
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FeaturedSlider;