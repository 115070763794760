export const locations = [
    'birmingham', 'new york', 'los angeles', 'chicago', 'miami', 'houston',
    'san francisco', 'dallas', 'phoenix', 'philadelphia', 'seattle', 'atlanta',
    'san diego', 'orlando', 'london', 'paris', 'tokyo', 'sydney', 'dubai',
    'berlin', 'toronto', 'singapore', 'mumbai', 'beijing', 'chennai',
    'amsterdam', 'madrid', 'barcelona', 'rome', 'lisbon', 'moscow',
    'stockholm', 'copenhagen', 'oslo', 'helsinki', 'dublin', 'brussels',
    'cairo', 'johannesburg', 'nairobi', 'accra', 'lagos', 'cape town',
    'santiago', 'bogota', 'lima', 'caracas', 'quito', 'asuncion',
    'kuala lumpur', 'jakarta', 'manila', 'bangkok', 'seoul', 'ho chi minh city',
    'hanoi', 'taipei', 'new delhi', 'kolkata', 'ahmedabad', 'pune',
    'canberra', 'wellington', 'auckland', 'christchurch', 'melbourne',
    'brisbane', 'perth', 'abu dhabi', 'doha', 'kuwait city', 'muscat',
    'copenhagen', 'stockholm', 'reykjavik', 'vilnius', 'tbilisi', 'batumi',
    'algiers', 'addis ababa', 'maputo', 'dar es salaam', 'tunis',
    'monrovia', 'freetown', 'harare', 'kinshasa', 'luanda', 'antananarivo',
    'port-au-prince', 'kingston', 'bridgetown', 'georgetown', 'st. john\'s',
    'nassau', 'castries', 'scarborough', 'tortola', 'st. thomas', 'st. croix',
    'santo domingo', 'puerto plata', 'santiago de los caballeros', 'punta cana',
    'curitiba', 'porto alegre', 'salvador', 'belo horizonte', 'recife',
    'fortaleza', 'manaus', 'florianopolis', 'goiania', 'campinas',
    'vancouver', 'calgary', 'edmonton', 'winnipeg', 'halifax', 'ottawa',
    'quebec city', 'st. john\'s', 'st. catharines', 'hamilton', 'windsor',
    'chattanooga', 'salt lake city', 'albuquerque', 'tucson', 'omaha',
    'kansas city', 'columbus', 'memphis', 'louisville', 'tampa', 'jacksonville',
    'madison', 'green bay', 'lansing', 'anchorage', 'honolulu', 'coimbatore', 'madurai', 'birmingham', 'huntsville', 'mobile', 'montgomery', 'anchorage',
    'chandler', 'gilbert', 'glendale', 'mesa', 'peoria', 'phoenix',
    'scottsdale', 'surprise', 'tempe', 'tucson', 'little rock',
    'anaheim', 'antioch', 'bakersfield', 'berkeley', 'burbank',
    'carlsbad', 'chula vista', 'clovis', 'compton', 'concord',
    'corona', 'costa mesa', 'daly city', 'downey', 'el cajon',
    'el monte', 'elk grove', 'escondido', 'fairfield', 'fontana',
    'fremont', 'fresno', 'fullerton', 'garden grove', 'hayward',
    'huntington beach', 'inglewood', 'irvine', 'lancaster', 'long beach',
    'los angeles', 'modesto', 'moreno valley', 'murrieta', 'newport beach',
    'norwalk', 'oakland', 'oceanside', 'ontario', 'orange', 'oxnard',
    'palmdale', 'pasadena', 'pomona', 'rancho cucamonga', 'rialto',
    'richmond', 'riverside', 'roseville', 'sacramento', 'salinas',
    'san bernardino', 'san diego', 'san francisco', 'san jose', 'san mateo',
    'santa ana', 'santa clara', 'santa clarita', 'santa maria', 'santa rosa',
    'simi valley', 'stockton', 'sunnyvale', 'temecula', 'thousand oaks',
    'torrance', 'vallejo', 'ventura', 'victorville', 'visalia',
    'walnut creek', 'west covina', 'arvada', 'aurora', 'boulder',
    'centennial', 'colorado springs', 'denver', 'fort collins', 'lakewood',
    'pueblo', 'thornton', 'westminster', 'bridgeport', 'hartford',
    'new haven', 'stamford', 'waterbury', 'washington', 'cape coral',
    'clearwater', 'coral springs', 'fort lauderdale', 'gainesville',
    'hialeah', 'hollywood', 'jacksonville', 'lakeland', 'miami',
    'miami gardens', 'miramar', 'orlando', 'palm bay', 'pembroke pines',
    'pompano beach', 'port st lucie', 'st petersburg', 'tallahassee',
    'tampa', 'west palm beach', 'athens', 'atlanta', 'augusta',
    'columbus', 'savannah', 'honolulu', 'boise city', 'chicago',
    'elgin', 'joliet', 'naperville', 'rockford', 'springfield',
    'evansville', 'fort wayne', 'indianapolis', 'south bend',
    'cedar rapids', 'davenport', 'des moines', 'kansas city', 'olathe',
    'overland park', 'topeka', 'wichita', 'lexington', 'louisville',
    'baton rouge', 'lafayette', 'new orleans', 'shreveport', 'baltimore',
    'boston', 'cambridge', 'lowell', 'worcester', 'ann arbor',
    'dearborn', 'detroit', 'flint', 'grand rapids', 'lansing',
    'sterling heights', 'warren', 'minneapolis', 'rochester',
    'st paul', 'jackson', 'columbia', 'independence', 'st louis',
    'billings', 'lincoln', 'omaha', 'henderson', 'las vegas',
    'north las vegas', 'reno', 'manchester', 'elizabeth', 'jersey city',
    'newark', 'paterson', 'albuquerque', 'las cruces', 'albany',
    'buffalo', 'new york city', 'syracuse', 'yonkers', 'cary',
    'charlotte', 'durham', 'fayetteville', 'greensboro', 'high point',
    'raleigh', 'wilmington', 'winston salem', 'fargo', 'akron',
    'cincinnati', 'cleveland', 'dayton', 'toledo', 'broken arrow',
    'norman', 'oklahoma city', 'tulsa', 'eugene', 'gresham',
    'portland', 'salem', 'allentown', 'erie', 'philadelphia',
    'pittsburgh', 'providence', 'charleston', 'north charleston',
    'sioux falls', 'chattanooga', 'clarksville', 'knoxville',
    'memphis', 'murfreesboro', 'nashville', 'abilene', 'amarillo',
    'arlington', 'austin', 'beaumont', 'brownsville', 'carrollton',
    'corpus christi', 'dallas', 'denton', 'el paso', 'fort worth',
    'frisco', 'garland', 'grand prairie', 'houston', 'irving',
    'killeen', 'laredo', 'lewisville', 'lubbock', 'mcallen',
    'mckinney', 'mesquite', 'midland', 'odessa', 'plano', 'richardson',
    'round rock', 'san antonio', 'tyler', 'waco', 'wichita falls',
    'provo', 'salt lake city', 'west jordan', 'west valley city',
    'alexandria', 'chesapeake', 'hampton', 'newport news', 'norfolk',
    'roanoke', 'virginia beach', 'bellevue', 'everett', 'kent',
    'seattle', 'spokane', 'tacoma', 'vancouver', 'green bay',
    'kenosha', 'madison', 'milwaukee', 'airdrie', 'calgary',
    'edmonton', 'grande prairie', 'lethbridge', 'medicine hat',
    'red deer', 'sherwood park', 'st. albert', 'abbotsford',
    'burnaby', 'chilliwack', 'coquitlam', 'delta', 'kamloops',
    'kelowna', 'langley', 'maple ridge', 'nanaimo', 'new westminster',
    'north vancouver', 'port coquitlam', 'prince george', 'saanich',
    'surrey', 'victoria', 'winnipeg', 'fredericton', 'moncton',
    'saint john', 'st. john\'s', 'cape breton', 'halifax', 'ajax',
    'barrie', 'belleville', 'brampton', 'brantford', 'burlington',
    'caledon', 'chatham', 'georgetown', 'guelph', 'halton hills',
    'hamilton', 'kawartha lakes', 'kingston', 'kitchener', 'london',
    'markham', 'milton', 'mississauga', 'newmarket', 'niagara falls',
    'north bay', 'oakville', 'orangeville', 'orillia', 'oshawa',
    'ottawa', 'peterborough', 'pickering', 'richmond hill', 'sarnia',
    'sault ste. marie', 'st. catharines', 'whitchurch-stouffville',
    'stratford', 'sudbury', 'thunder bay', 'toronto', 'vaughan',
    'waterloo', 'welland', 'whitby', 'windsor', 'blainville',
    'brossard', 'dollard des ormeaux', 'drummondville', 'gatineau',
    'granby', 'laval', 'levis', 'longueuil', 'mirabel', 'montreal',
    'quebec', 'repentigny', 'saguenay', 'saint-hyacinthe', 'saint jean sur richelieu',
    'saint-jérôme', 'shawinigan', 'sherbrooke', 'terrebonne', 'trois-rivières',
    'regina', 'saskatoon', 'canberra', 'albury', 'bathurst',
    'bomaderry', 'bowral', 'central coast', 'coffs harbour', 'dubbo',
    'maitland', 'mittagong', 'newcastle', 'nowra', 'port macquarie',
    'queanbeyan', 'sydney', 'tamworth', 'tweed heads', 'wagga wagga',
    'wentworth', 'wollongong', 'alice springs', 'darwin', 'brisbane', 'bundaberg', 'cairns', 'gladstone',
    'gold coast', 'gympie', 'hervey bay', 'ipswich', 'logan city',
    'mackay', 'maryborough', 'mount isa', 'rockhampton',
    'sunshine coast', 'toowoomba', 'townsville', 'warwick',
    'adelaide', 'gawler', 'mount gambier', 'port augusta',
    'port lincoln', 'port pirie', 'whyalla', 'hobart', 'launceston',
    'devonport', 'burnie', 'melbourne', 'ballarat', 'bendigo',
    'geelong', 'mildura', 'shepparton', 'warrnambool',
    'wodonga', 'perth', 'albany', 'bunbury', 'geraldton',
    'kalgoorlie', 'mandurah', 'ayer rajah',
  'bishan','bukit merah',
    'buona vista',
    'chinatown',
    'geylang',
    'kallang',
    'little india',
    'macpherson',
    'marina bay',
    'marine parade',
    'orchard road',
    'outram',
    'queenstown',
    'raffles place',
    'tanjong pagar',
    'telok blangah',
    'tiong bahru',
    'toa payoh',
    'bedok',
    'changi',
    'pasir ris',
    'tampines',
    'sembawang',
    'woodlands',
    'yishun',
    'ang mo kio',
    'hougang',
    'punggol',
    'sengkang',
    'serangoon',
    'boon lay',
    'bukit batok',
    'bukit panjang',
    'choa chu kang',
    'clementi',
    'jurong east',
    'jurong west',
    'pioneer',
    'tuas',
    'guntur',
    'nellore',
    'tirupati',
    'vijayawada',
    'visakhapatnam',
    'guwahati',
    'gaya',
    'patna',
    'chandigarh',
    'bhilai',
    'bilaspur',
    'raipur',
    'delhi',
    'ahmedabad',
    'bhavnagar',
    'jamnagar',
    'rajkot',
    'surat',
    'vadodara',
    'faridabad',
    'gurugram',
    'jammu',
    'srinagar',
    'dhanbad',
    'jamshedpur',
    'ranchi',
    'belgaum',
    'bengaluru',
    'gulbarga',
    'hubballi dharwad',
    'mangalore',
    'mysore',
    'kochi',
    'thiruvananthapuram',
    'bhopal',
    'gwalior',
    'indore',
    'jabalpur',
    'ujjain',
    'akola',
    'amravati',
    'aurangabad',
    'bhiwandi',
    'kalyan dombivali',
    'kolhapur',
    'malegaon',
    'mira bhayandar',
    'mumbai',
    'nagpur',
    'nanded',
    'nashik',
    'navi mumbai',
    'pune',
    'solapur',
    'thane',
    'ulhasnagar',
    'vasai virar',
    'bhubaneswar',
    'cuttack',
    'rourkela',
    'pondicherry',
    'amritsar',
    'jalandhar',
    'ludhiana',
    'ajmer',
    'bikaner',
    'jaipur',
    'jodhpur',
    'kota',
    'coimbatore',
    'erode',
    'madurai',
    'tiruchirappalli',
    'tirunelveli',
    'tiruppur',
    'hyderabad',
    'warangal',
    'agra',
    'aligarh',
    'allahabad',
    'bareilly',
    'firozabad',
    'ghaziabad',
    'gorakhpur',
    'jhansi',
    'kanpur',
    'loni',
    'lucknow',
    'meerut',
    'moradabad',
    'noida',
    'saharanpur',
    'varanasi',
    'dehradun',
    'asansol',
    'durgapur',
    'howrah',
    'kolkata',
    'siliguri',
    'aberdeen',
    'aylesbury vale',
    'barnsley',
    'basildon',
    'basingstoke deane',
    'bath',
    'belfast',
    'blackburn',
    'blackpool',
    'bolton',
    'bournemouth',
    'bradford',
    'brentwood',
    'bridgend',
    'brighton',
    'bristol',
    'bury',
    'caerphilly',
    'calderdale',
    'canterbury',
    'cardiff',
    'carlisle',
    'carmarthenshire',
    'charnwood',
    'chelmsford',
    'chester',
    'chorley',
    'colchester',
    'cornwall',
    'coventry',
    'crewe',
    'dartford',
    'derby',
    'doncaster',
    'dudley',
    'dumfries & galloway',
    'dundee',
    'ealing',
    'east riding',
    'eastbourne',
    'edinburgh',
    'exeter',
    'exmouth',
    'falkirk',
    'fife',
    'flintshire',
    'gateshead',
    'giffnock',
    'glasgow',
    'gloucester',
    'grantham',
    'gwynedd',
    'harrogate',
    'hereford',
    'highland',
    'huddersfield',
    'huntingdonshire',
    'ipswich',
    'kingston upon hull',
    'knowsley',
    'leeds',
    'leicester',
    'lisburn',
    'liverpool',
    'luton',
    'macclesfield',
    'maidstone',
    'merthyr tydfil',
    'middlesbrough',
    'milton keynes',
    'mold',
    'new forest',
    'newark on trent',
    'newbury',
    'newcastle upon tyne',
    'newport',
    'north ayrshire',
    'north east lincolnshire',
    'north lanarkshire',
    'north lincolnshire',
    'north somerset',
    'north tyneside',
    'northampton',
    'norwich',
    'nottingham',
    'oldham',
    'oxford',
    'plymouth',
    'poole',
    'portsmouth',
    'preston',
    'reading',
    'renfrewshire',
    'rhondda cynon taff',
    'ripon',
    'rochdale',
    'rochester upon medway',
    'rotherham',
    'salford',
    'salisbury',
    'sandwell',
    'sefton',
    'sheffield',
    'shiremoor',
    'sittingbourne',
    'slough',
    'solihull',
    'south gloucestershire',
    'south lanarkshire',
    'south somerset',
    'south tyneside',
    'southampton',
    'southend on sea',
    'st albans',
    'st helens',
    'stafford',
    'stevenage',
    'stockport',
    'stockton on tees',
    'stoke on trent',
    'sunderland',
    'swansea',
    'swindon',
    'tameside',
    'the wrekin',
    'torquay',
    'trafford',
    'vale of glamorgan',
    'wakefield',
    'walsall',
    'waltham abbey',
    'warrington',
    'watford',
    'wembley',
    'west lothian',
    'wigan',
    'winchester',
    'wirral',
    'wokingham',
    'wolverhampton',
    'worcester park',
    'worthing',
    'wrexham',
    'wycombe',
    'york',
    'grenoble',
    'lyon',
    'saint-étienne',
    'dijon',
    'rennes',
    'reims',
    'strasbourg',
    'lille',
    'paris',
    'saint-denis',
    'le havre',
    'bordeaux',
    'montpellier',
    'nîmes',
    'toulouse',
    'angers',
    'nantes',
    'marseille',
    'nice',
    'toulon',
  ];