import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchBarBanner from '../SinglePage/SearchBarBanner';
import ReviewsList from '../SinglePage/ReviewsList';
import BookingForm from '../SinglePage/BookingForm';
import AuthorBox from '../SinglePage/AuthorBox';
import BusinessInfo from '../SinglePage/BusinessInfo';
import RelatedListings from '../SinglePage/RelatedListings';
import FeaturedSlider from '../SinglePage/FeaturedSlider';
import AboutBusiness from '../SinglePage/AboutBusiness';
import BusinessMenu from '../SinglePage/BusinessMenu';
import AmenitiesAndMore from '../SinglePage/AmentiesAndMore';
import FAQSection from '../SinglePage/FAQSection';
import LocationHours from '../SinglePage/LocationHours';
import ContactInfo from '../SinglePage/ContactInfo';
import DarkHeader from '../HomePage/DarkHeader';
import OrderForm from '../SinglePage/OrderForm';
import AppointmentForm from '../SinglePage/AppointmentForm';
import BeautifulSpinner from '../Helper/BeautifulSpinner';
import { Helmet } from 'react-helmet'; // Import Helmet

const SingleListingTemplate = () => {
    const { location, name, businessDetailID } = useParams();
    const [listingData, setListingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [jwtToken, setJwtToken] = useState(null);


    // const apiUrl = `https://api.localbizs.com/api/BestBusiness/${businessDetailID}`;
    // const apiUrl = `http://localhost:5294/api/BestBusiness/${businessDetailID}`;
    const apiUrl = `/api/bizsbyid/${businessDetailID}`;


    // Authenticate and get JWT token
    const authenticateUser = async () => {
        try {
            const response = await fetch('/api/Authenticate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: 0,
                    username: "siva",  // You can replace these with dynamic user inputs
                    password: "kumar",
                    role: "user",
                    scopes: "read"
                })
            });

            if (response.ok) {
                const data = await response.json();
                setJwtToken(data.jwtToken);  // Store JWT token in state
                localStorage.setItem('jwtToken', data.jwtToken); // Optionally store in localStorage
            } else {
                setError('Authentication failed');
            }
        } catch (error) {
            setError('Error during authentication: ' + error.message);
        }
    };


    const handleAppointmentSubmit = (formData) => {
       // console.log('Appointment Data:', formData);
    };

    // Fetch listing data
    useEffect(() => {
        // Authenticate before fetching data
        authenticateUser();

        const fetchListing = async () => {
            if (!jwtToken) return;  // Wait for token

            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`, // Attach JWT token
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setListingData(data);
                } else {
                    setError('Failed to fetch listing data');
                }
            } catch (error) {
                setError(`Error fetching listing data: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchListing();
    }, [businessDetailID, jwtToken]);  // Trigger when jwtToken is available

    if (loading) {
        return (
            <div>
                <BeautifulSpinner /> {/* Show spinner while loading */}
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    const getAdjectiveBySector = (sector) => {
        if (!sector) return "Reliable"; // Default fallback for undefined sectors

        switch (sector.toLowerCase()) {
            case "best doctors":
            case "best health":
            case "meilleurs médecins":
            case "meilleurs santé":
                return "Trusted";

            case "best lawyers":
            case "best solicitors":
            case "meilleurs avocats":
                return "Experienced";

            case "best restaurants":
            case "best speciality cuisine":
            case "best speciality food":
            case "best food":
            case "meilleurs cuisine de spécialité":
            case "meilleurs restaurants":
                return "Top-Rated";

            case "best entertainment":
            case "meilleurs divertissement":
                return "Favorite";

            case "best local businesses":
            case "best local services":
            case "best professional services":
            case "best home services":
            case "meilleurs entreprises locales":
            case "meilleurs services locaux":
            case "meilleurs services professionnels":
                return "Reliable";

            case "best travel and spa":
                return "Relaxing";

            case "best shopping":
                return "Popular";

            case "best education":
                return "Leading";

            default:
                return "Reliable"; // Default fallback
        }
    };
    const getDescriptionBySector = (sector) => {
        switch (sector.toLowerCase()) {
            case "best doctors":
            case "best health":
                return "Find trusted healthcare providers with detailed reviews, ratings, and working hours. Your health is our priority!";
            case "best lawyers":
            case "best solicitors":
                return "Connect with top legal experts who can guide you with professional advice and trusted expertise.";
            case "best restaurants":
            case "best speciality cuisine":
            case "best food":
                return "Explore top-rated restaurants offering delightful cuisines, menus, and customer reviews.";
            case "best entertainment":
                return "Discover favorite entertainment spots with ratings, reviews, and exciting experiences nearby.";
            case "best local businesses":
            case "best local services":
            case "best professional services":
            case "best home services":
                return "Explore reliable local services and businesses that meet all your professional and household needs.";
            case "best education":
                return "Find leading educational institutions with ratings, reviews, and program details.";
            case "best shopping":
                return "Discover top shopping destinations for a seamless and enjoyable retail experience.";
            case "best travel and spa":
                return "Unwind at the best travel destinations and spa services offering relaxation and rejuvenation.";
            default:
                return "Find top-rated businesses with detailed reviews, working hours, and exceptional services near you.";
        }
    };
    function toProfessionalTitleCase(input) {
        const smallWords = new Set(["and", "or", "near", "the", "of", "in", "on", "to", "with", "by", "for", "a", "an", "at", "but", "is"]); // Words to keep lowercase unless they are at the start of the string
        
        return input
            .toLowerCase() // Convert the entire string to lowercase first
            .split(" ") // Split the string into words
            .map((word, index) => {
                // Capitalize words unless they are small words and not the first word
                if (index === 0 || !smallWords.has(word)) {
                    return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize first letter
                }
                return word; // Return as lowercase
            })
            .join(" "); // Join the words back together
    }
    
    const seoTitle = toProfessionalTitleCase(`${listingData.name || "Business Name"} - ${getAdjectiveBySector(listingData.sectorName) || "Reliable"} ${listingData.sectorName || "Business"} Near ${listingData.cityName || "Your Location"}`);   
    const seoDescription = toProfessionalTitleCase(`${listingData.name || 'Discover businesses'} in ${
        listingData.cityName || 'your area'
    }. ${getDescriptionBySector(listingData.sectorName || 'business')}`);
    const seoImage = "/images/images1/" + (listingData.image1_URL || "default.jpg");
    const seoUrl = window.location.href;

    const isHealthOrDoctorSector =
        listingData.sectorName === 'Best Doctors' || listingData.sectorName === 'Best Health';
    const isEntertainmentSector = listingData.sectorName === 'Best Entertainment';

    const isRestaurantSector =
        listingData.sectorName === 'Best Restaurants' || listingData.sectorName === 'Best Speciality Cuisine' || listingData.sectorName === 'Best Food';
    const isLawyer = listingData.sectorName === 'Best Lawyers';
    const isEntertainment = listingData.sectorName === 'Best Entertainment'; // Added entertainment sector check
    const isShopping = listingData.sectorName === 'Best Shopping'; // Added entertainment sector check
    const isLocalService= listingData.sectorName === 'Best Local Services';
    const isProfessional= listingData.sectorName === 'Best Professional Services';


    const workingHours = listingData.workingHours || "Closed"; // Fallback to "Closed" if null or undefined
    const locationData = {
        address: listingData.address,
        cityName: listingData.cityName,
        stateName: listingData.stateName,
        countryName: listingData.countryName,
        coordinates: [13.0827, 80.2707], // Latitude and Longitude for Chennai
    };

    return (
        <div id="main-wrapper">
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDescription} />
                <meta property="og:image" content={seoImage} />
                <meta property="og:url" content={seoUrl} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDescription} />
                <meta name="twitter:image" content={seoImage} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <SearchBarBanner
                dummyBannerData={{
                    imageSrc: 'https://localbizs.s3.eu-north-1.amazonaws.com'+listingData.image1_URL || '',
                    // title: `${listingData?.name || ''} ${listingData?.degree ? listingData.degree + " " : ""}${listingData?.clinicname || ""}`.trim(),
                    title: seoTitle,
                    degree: listingData.degree,
                    clinicName: listingData.clinicName,
                    reviews: listingData.reviewCount || 0,
                    rating: listingData.rating || 0,
                    priceRange: 3,
                    claimed: true,
                    categories: [listingData.professional],
                    status: listingData.workingHours ? 'Open' : 'Closed',
                    hours: listingData.workingHours,
                }}
            />

            <section className="py-5 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                            {listingData.image1_URL && (
                                <FeaturedSlider
                                    slider={{
                                        sliderData: [
                                            { url: 'https://localbizs.s3.eu-north-1.amazonaws.com'+listingData.image1_URL, alt: '' },
                                            { url:'https://localbizs.s3.eu-north-1.amazonaws.com'+listingData.image2_URL, alt: '' },
                                            { url: 'https://localbizs.s3.eu-north-1.amazonaws.com'+listingData.image3_URL, alt: '' },
                                        ],
                                    }}
                                />
                            )}
                            <AboutBusiness description={{ aboutContent: listingData.description }} />
                            {/* <BusinessMenu menu={listingData.specialties} /> */}
                            {/* <AmenitiesAndMore amenities={{ amenitiess: [listingData.specialties] }} /> */}
                            <AmenitiesAndMore
                                amenities={
                                    listingData.specialties
                                        .replace("CONDITIONS:", "") // Remove the prefix
                                        .split(",") // Split by commas
                                        .map((s) => s.trim()) // Trim each item
                                }
                            />
                            <FAQSection isRestaurant={isRestaurantSector} isHealthOrDoctor={isHealthOrDoctorSector} isLawyer={isLawyer} isEntertainment={isEntertainment} isLocalService={isLocalService} isShopping={isShopping} isProfessional={isProfessional} />;
                            {/* <ReviewsSection reviews={listingData.reviews || []} /> */}
                            <ReviewsList sectorName={listingData.sectorName} />  
                         
                            {/* <LocationHours locationData={locationData} workingHours={workingHours} /> */}
                            </div>
                      
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <ContactInfo
                                contactInfo={{
                                    phone: listingData.phoneNumber,
                                    email: listingData.email,
                                    address: listingData.address,
                                }}
                            />
                            {isHealthOrDoctorSector ? (
                                <AppointmentForm
                                    initialDate="2024-10-24"
                                    onSubmit={handleAppointmentSubmit}
                                />
                            ) : isEntertainmentSector ? (
                                <BookingForm bookingData={{ checkIn: '2024-09-28' }} />
                            ) : isShopping ? (
                                <BookingForm bookingData={{ checkIn: '2024-09-28' }} />
                            ) : isLocalService ? (
                                <BookingForm bookingData={{ checkIn: '2024-09-28' }} />
                            ) : isProfessional ? (
                                <BookingForm bookingData={{ checkIn: '2024-09-28' }} />
                            ) : (
                                <>
                                    <OrderForm
                                        deliveryFee={listingData?.deliveryFee || 0.99}
                                        minOrder={listingData?.minOrder || 0}
                                        deliveryTime={listingData?.deliveryTime || '35-45'}
                                    />
                                    <BookingForm bookingData={{ checkIn: '2024-09-28' }} />
                                </>
                            )}

                            {/* <BusinessInfo
                                business={{
                                    website: listingData.websiteURL,
                                    email: listingData.email,
                                    phone: listingData.phoneNumber,
                                    address: listingData.address,
                                }}
                            /> */}
                            {/* <AuthorBox
                                author={{
                                    name: listingData.Name,
                                    location: `${listingData.cityName}, ${listingData.stateName}`,
                                    image: "/images/images2/" + listingData.image2_URL,
                                    ratings: listingData.rating,
                                }}
                            /> */}
                           
                            <RelatedListings
                                relatedListings={[
                                    { id: 1, title: 'Cozy Cottage', description: 'A cozy cottage in the woods.' },
                                    { id: 2, title: 'Beach House', description: 'A beautiful beach house with ocean views.' },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SingleListingTemplate;