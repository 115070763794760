import React, { useState, useEffect } from 'react';
import DynamicSearchForm from './DynamicSearchForm';

const BestSection = () => {
  const [currentContent, setCurrentContent] = useState(0); // Start from the first item in the array

  // Array of content (businesses) that will change every 10 seconds
  const contentArray = [
    {
      title: "Best in Canada",
      description: "Visit The Best Spa & Hotels in California",
      businessInfo:
        "Discover the ultimate luxury and relaxation in California's finest spas and hotels. Experience unparalleled hospitality and amenities.",
    },
    {
      title: "Best in USA",
      description: "Explore The Top Restaurants & Cafes in New York",
      businessInfo:
        "From fine dining to cozy cafes, New York offers a world-class culinary journey that delights every palate.",
    },
    {
      title: "Best in UK",
      description: "Discover Premier Shopping Spots in London",
      businessInfo:
        "London's shopping districts are a blend of elegance and variety, offering an unforgettable retail therapy experience.",
    },
    // Add more items as needed
  ];

  // Update content every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContent((prevContent) => (prevContent + 1) % contentArray.length); // Loop through content array
    }, 10000); // 10 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <section
      className="best-section bg-cover text-center py-5"
      style={{
        backgroundImage: "url(https://via.placeholder.com/1920x950)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      {/* Semi-transparent overlay */}
      <div
        className="overlay"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      ></div>

      <div className="container position-relative" style={{ zIndex: 2 }}>
        {/* Section Heading */}
        <div className="row justify-content-center mb-4">
          <div className="col-lg-8 col-md-10 text-center">
            <h2 className="text-light display-4 font-weight-bold mb-3">
              {contentArray[currentContent].title}
            </h2>
            <h4 className="text-light mb-4">
              {contentArray[currentContent].description}
            </h4>
            <p className="text-light lead mb-4">
              {contentArray[currentContent].businessInfo}
            </p>
          </div>
        </div>

        {/* Call to Action Button */}
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <a
              href="#"
              className="btn btn-primary btn-lg px-5 py-3 shadow-sm"
              style={{
                backgroundColor: "#f41b3b",
                border: "none",
                borderRadius: "50px",
                fontWeight: "bold",
              }}
            >
              Explore & Submit Listings
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestSection;