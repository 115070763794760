import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DynamicSearchForm from './DynamicSearchForm';

const Header = () => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false); // State to toggle mobile menu

    // Excluded pages where the search bar shouldn't appear
    const isExcludedPage = [
        '/contact-us',
        '/about-us',
        '/add-business',
        '/privacy-policy',
        '/terms-conditions',
    ].includes(location.pathname);

    return (
        <header className="header header-sticky">
            <div className="container">
                <div className="header-inner">
                    {/* Logo */}
                    <div className="logo">
                        <Link to="/">
                            <img
                                src="/assets/img/logo-light.png"
                                className="logo static-logo"
                                alt="LocalBizs Logo"
                            />
                        </Link>
                    </div>

                    {/* Dynamic Search Form */}
                    {!isExcludedPage && (
                        <div className="search-form-container">
                            <DynamicSearchForm />
                        </div>
                    )}

                    {/* Mobile Menu Toggle */}
                    <button
                        className="mobile-menu-toggle"
                        onClick={() => setMenuOpen(!menuOpen)}
                        aria-label="Toggle Menu"
                    >
                        ☰
                    </button>

                    {/* Navigation Menu */}
                    <nav
                        className={`nav-menus-wrapper ${menuOpen ? 'menu-open' : ''}`}
                    >
                        <ul className="nav-menu">
                            <li>
                                <Link to="/add-business">Add a Business</Link>
                            </li>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <style jsx>{`
                /* General Header Styles */
                .header {
                    background: #fff;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    position: sticky;
                    top: 0;
                    z-index: 1000;
                    padding: 10px 0;
                }

                .container {
                    max-width: 1200px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .header-inner {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                }

                /* Logo Section */
                .logo img {
                    max-height: 50px;
                }

                /* Search Form Section */
                .search-form-container {
                    padding: 0 15px;
                }

                /* Navigation Menu */
                .nav-menus-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .nav-menu {
                    list-style: none;
                    display: flex;
                    gap: 1px;
                    margin: 0;
                    padding: 0;
                      white-space: nowrap; /* Prevent wrapping of menu items */

      overflow: hidden; /* Ensure content stays within bounds */
                }

                .nav-menu li a {
                    text-decoration: none;
                    color: #333;
                    font-size: 16px;
                    font-weight: 500;
                    transition: color 0.3s;
                }

                .nav-menu li a:hover {
                    color: #f41b3b;
                }

                /* Mobile Menu Toggle */
                .mobile-menu-toggle {
                    display: none;
                    background: none;
                    border: none;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                /* Responsive Design */
                @media (max-width: 768px) {
                    .header-inner {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .search-form-container {
                        width: 100%;
                        margin: 10px 0;
                    }

                    .mobile-menu-toggle {
                        display: block;
                    }

                    .nav-menus-wrapper {
                        display: none;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 100%;
                        background: #fff;
                        padding: 10px 0;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    }

                    .nav-menus-wrapper.menu-open {
                        display: flex;
                    }

                    .nav-menu {
                        flex-direction: column;
                        gap: 10px;
                        width: 100%;
                    }

                    .nav-menu li {
                        width: 100%;
                        text-align: left;
                        padding: 0 15px;
                    }

                    .nav-menu li a {
                        font-size: 14px;
                    }
                }
            `}</style>
        </header>
    );
};

export default Header;