// BookingForm.js
import React, { useState } from 'react';

const BookingForm = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1; // getMonth() is zero-based
    const day = today.getDate();
    const year = today.getFullYear();
    return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
  };

  const [checkIn, setCheckIn] = useState(getCurrentDate());
  const [checkOut, setCheckOut] = useState(getCurrentDate());
  const [adults, setAdults] = useState(2);
  const [kids, setKids] = useState(0);
  const [features, setFeatures] = useState({
    airCondition: false,
    bedding: true,
    heating: true,
    internet: false,
    microwave: false,
  });

  // Handle feature checkbox change
  const handleFeatureChange = (feature) => {
    setFeatures({ ...features, [feature]: !features[feature] });
  };

  // Increase or decrease guest counts
  const handleCounter = (type, operation) => {
    if (type === 'adults') {
      setAdults((prev) => Math.max(1, operation === 'increment' ? prev + 1 : prev - 1));
    } else if (type === 'kids') {
      setKids((prev) => Math.max(0, operation === 'increment' ? prev + 1 : prev - 1));
    }
  };

  return (
    <div className="jb-apply-form bg-white rounded py-4 px-4 border mb-4">
      <h4 className="ft-bold mb-1">Book Your Order</h4>

      <div className="Goodup-boo-space mt-3">
        <div className="row">
          {/* Check-in and Check-out */}
          {['Check In', 'Check Out'].map((label, index) => (
            <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={label}>
              <div className="form-group mb-3">
                <label className="ft-medium small mb-1">{label}</label>
                <div className="cld-box">
                  <i className="ti-calendar"></i>
                  <input
                    type="text"
                    className="form-control"
                    value={index === 0 ? checkIn : checkOut}
                    onChange={(e) => index === 0 ? setCheckIn(e.target.value) : setCheckOut(e.target.value)}
                    placeholder={label}
                  />
                </div>
              </div>
            </div>
          ))}

          {/* Adults and Kids Counters */}
          {[
            { label: 'Adults', count: adults, handler: 'adults' },
            { label: 'Kids', count: kids, handler: 'kids' },
          ].map(({ label, count, handler }) => (
            <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={label}>
              <div className="form-group mb-3">
                <div className="guests">
                  <label className="ft-medium small mb-1">{label}</label>
                  <div className="guests-box">
                    <button className="counter-btn" type="button" onClick={() => handleCounter(handler, 'decrement')}>
                      <i className="ti-minus"></i>
                    </button>
                    <input type="text" value={count} readOnly />
                    <button className="counter-btn" type="button" onClick={() => handleCounter(handler, 'increment')}>
                      <i className="ti-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Feature List */}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <h6 className="ft-medium">Advance Features</h6>
            <div className="_adv_features_list">
              <ul className="no-ul-list">
                {[
                  { id: 'airCondition', label: 'Air Condition', price: '$10' },
                  { id: 'bedding', label: 'Bedding', price: '$07' },
                  { id: 'heating', label: 'Heating', price: '$20' },
                  { id: 'internet', label: 'Internet', price: '$10' },
                  { id: 'microwave', label: 'Microwave', price: '$05' },
                ].map((feature) => (
                  <li key={feature.id}>
                    <input
                      id={feature.id}
                      className="checkbox-custom"
                      type="checkbox"
                      checked={features[feature.id]}
                      onChange={() => handleFeatureChange(feature.id)}
                    />
                    <label htmlFor={feature.id} className="checkbox-custom-label">
                      {feature.label}
                      <i>{feature.price}</i>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Price & Taxes */}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <h6 className="ft-medium">Price & Taxes</h6>
            <div className="_adv_features">
              <ul>
                <li>1 Night<span>$170</span></li>
                <li>Discount 25$<span>-$210</span></li>
                <li>Service Fee<span>$13</span></li>
                <li>Breakfast Per Adult<span>$24</span></li>
              </ul>
            </div>
          </div>

          {/* Total Payment */}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <div className="Goodup-boo-space-foot mb-3">
              <span className="Goodup-boo-space-left">Total Payment</span>
              <h4 className="ft-bold theme-cl">$218</h4>
            </div>
          </div>

          {/* Book Now Button */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            <a href="javascript:void(0)" className="btn text-light rounded full-width theme-bg ft-medium">
              Book Order Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;