import React from 'react';

const SearchBarBanner = ({ dummyBannerData = {} }) => {
    const {
        imageSrc = "assets/img/placeholder.png", // Fallback image
        title = "No Title Available",
        degree = "N/A",
        clinicName = "N/A",
        reviews = 0,
        rating = 0,
        priceRange = 1,
        claimed = false,
        categories = [],
        status = "Closed",
        hours = "N/A",
    } = dummyBannerData;

    const renderStars = (rating) => (
        [...Array(5)].map((_, i) => (
            <i key={i} className={`fas fa-star ${i < rating ? 'active' : ''}`}></i>
        ))
    );

    const renderPriceRange = (range) => (
        [...Array(range)].map((_, i) => (
            <span key={i} className="active">
                <i className="fas fa-dollar-sign"></i>
            </span>
        ))
    );

    return (
        <section className="featured-wrap gray py-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="Goodup-ops-bhriol p-4 bg-white shadow-sm rounded">
                            <div className="Goodup-lkp-flex d-flex align-items-start">
                                {/* Image Section */}
                                <div className="Goodup-lkp-thumb">
                                    <img 
                                        src={imageSrc} 
                                        className="img-fluid rounded" 
                                        width="90" 
                                        alt={title} 
                                    />
                                </div>
                                
                                {/* Info Section */}
                                <div className="Goodup-lkp-caption ps-3">
                                    {/* Title */}
                                    <h1 className="mb-1 ft-bold">{title}</h1>
                                    <h2 className="mb-1 text-muted">{degree}</h2>
                                    <h3 className="mb-3 text-primary">{clinicName}</h3>

                                    {/* Ratings and Reviews */}
                                    <div className="Goodup-ft-first d-flex align-items-center">
                                        <div className="Goodup-rating me-3">
                                            {renderStars(rating)}
                                        </div>
                                        <span className="text-muted">
                                            {reviews} Reviews
                                        </span>
                                    </div>

                                    {/* Price Range */}
                                    <div className="Goodup-price-range mt-2">
                                        <span className="text-muted">Price Range: </span>
                                        {renderPriceRange(priceRange)}
                                    </div>

                                    {/* Claimed & Categories */}
                                    <div className="d-block mt-3">
                                        <div className="list-lioe d-flex flex-wrap align-items-center">
                                            <span 
                                                className={`badge bg-${claimed ? 'info' : 'secondary'} text-white me-3`}>
                                                {claimed ? "Claimed" : "Unclaimed"}
                                            </span>
                                            {categories.length > 0 && (
                                                <div className="list-categories">
                                                    {categories.map((category, index) => (
                                                        <a 
                                                            key={index} 
                                                            href="#" 
                                                            className="badge bg-light text-dark me-2">
                                                            {category}
                                                        </a>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Status & Hours */}
                                    <div className="d-block mt-2">
                                        <span 
                                            className={`badge bg-${status === 'Closed' ? 'danger' : 'success'} text-white me-3`}>
                                            {status}
                                        </span>
                                        <span className="text-muted">{hours}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SearchBarBanner;