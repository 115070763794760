import React, { useState, useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom'; // Import useParams
import DarkHeader from '../HomePage/DarkHeader';
import CatsFilters from './CatFilters';
import { Helmet } from 'react-helmet'; // Import Helmet
import Header from '../HomePage/Header';
import BeautifulSpinner from '../Helper/BeautifulSpinner';
import DotSpinner from '../Helper/DotSpinner';
import ReadMore from './ReadMore';
import NotFoundPage from '../Menu/NotFoundPage';
import ErrorPage from '../Helper/ErrorPage';
const BusinessListing = () => {
    const [businesses, setBusinesses] = useState([]); // Initialize as an array
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [totalPages, setTotalPages] = useState(0);
    const location = useLocation(); // Get the location object
    const { location: locationParam, query, category = '' } = useParams(); // E
    const processedQuery = query.toLowerCase().replace(/-/g, ' ').trim();
    const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwtToken') || null); // JWT token
    const [error, setError] = useState(''); // Error state
    const [loading, setLoading] = useState(true); // New state
    // Function to authenticate user and fetch a JWT token
    const authenticateUser = async () => {
        try {
            // Sending a POST request to authenticate
            const response = await fetch('/api/Authenticate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: 0,
                    username: 'siva', // Replace with dynamic input if needed
                    password: 'kumar',
                    role: 'user',
                    scopes: 'read',
                }),
            });
    
            // Check if the response is successful (status 200-299)
            if (response.ok) {
                // Check if the content type is JSON
                const contentType = response.headers.get('Content-Type');
                if (!contentType || !contentType.includes('application/json')) {
                    throw new Error('Expected JSON response, but received ' + contentType);
                }
    
                // Parse the response as JSON
                const data = await response.json();
    
                // Save the JWT token in the state and localStorage
                setJwtToken(data.jwtToken); 
                localStorage.setItem('jwtToken', data.jwtToken); // Optionally save to localStorage
    
                return data.jwtToken;
            } else {
                // If the response is not OK, throw an error
                throw new Error(`Authentication failed with status: ${response.status}`);
            }
        } catch (err) {
            // Catch any error and log it
            console.error('Authentication error:', err);
            setError(`Error during authentication: ${err.message}`);
    
            // Optionally, you can check the status of the response (if available) and show more detailed errors
            if (err.message.includes('Authentication failed')) {
                setError('Invalid credentials or server issue.');
            }
            
            return null;
        }
    };
    // Fetch businesses with token
    const fetchBusinesses = async (token) => {
        setLoading(true); // Start loading
        try {
            //  const response = await fetch(`https://api.localbizs.com/api/search/all?query=${encodeURIComponent(processedQuery)}&location=${encodeURIComponent(locationParam)}&category=${encodeURIComponent(category)}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`, {
            const response = await fetch(
                `/api/all?query=${encodeURIComponent(processedQuery)}&location=${encodeURIComponent(locationParam)}&category=${encodeURIComponent(category)}&pageNumber=${currentPage}&pageSize=${itemsPerPage}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`, // Include JWT token
                    },
                    mode: 'cors',
                }
            );
            if (response.ok) {
                const data = await response.json();
                setBusinesses(data.results.$values || []);
                setTotalPages(data.totalPages || 1000);
            } else {
                throw new Error(`Failed to fetch businesses: ${response.status}`);
            }
        } catch (err) {
            console.error('Error fetching businesses:', err);
            setError(`Error fetching businesses: ${err.message}`);
        } finally {
            setLoading(false); // End loading
        }
    };
    useEffect(() => {
        const initialize = async () => {
            let token = jwtToken;

            if (!token) {
                console.warn('No token found, authenticating...');
                token = await authenticateUser(); // Authenticate and get a new token
            }
            if (token) {
              //  console.log('Using JWT Token:', token);
                fetchBusinesses(token); // Fetch businesses after token is available
            } else {
                console.error('Failed to authenticate. Aborting business fetch.');
            }
        };
        initialize();
    }, [currentPage, itemsPerPage, query, locationParam, category]);

    // Logic for pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const title = `${currentPage > 1 ? `Page ${currentPage} of ` : ''}Best ${query || 'Businesses'} in ${locationParam || 'Your Location'} | Top Listings Near You`;
    // const title = `Best ${category || 'Businesses'} in ${locationParam || 'Your Location'} - Page ${currentPage}`;
    const description = `Explore top ${query || 'businesses'} in ${locationParam || 'your area'} with ratings, reviews, and working hours. Find the best match for your needs.`;

    const keywords = `${query}, ${locationParam}, businesses, reviews, listings`;
    // Calculate page range
    const maxVisiblePages = 10; // Maximum number of visible pages
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage if endPage is near totalPages
    if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - maxVisiblePages + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    const generateSEOUrl = (location = '', name = '', id) => {
        const seoLocation = location
            .toLowerCase()
            .trim()                                 // Remove leading and trailing spaces
            .replace(/[^a-z0-9]+/g, '-')            // Replace all special characters and spaces with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading and trailing hyphens

        const seoName = name
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/^-+|-+$/g, '');               // Remove leading and trailing hyphens

        return `/bizs/${seoLocation}/${seoName}/${id}`;
    };

    // Show the custom NotFound component on error
    if (error) {
        const errorCode = error.includes('404') ? 404 : 500; // Determine error type
        return <ErrorPage errorCode={errorCode} message={error} />;
    }
    return (
        <div id="main-wrapper">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="URL_TO_IMAGE" /> {/* Replace with an appropriate image URL */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="URL_TO_IMAGE" /> {/* Replace with an appropriate image URL */}
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            {loading ? (
                <DotSpinner />
            ) : (
                <>
                    <div className="clearfix"></div>
                    {/* <CatsFilters></CatsFilters> */}
                    <section className="gray py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="row justify-content-center g-2">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item">
                                                        <Link to="/">Home</Link>
                                                    </li>
                                                    {locationParam && (
                                                        <li className="breadcrumb-item">
                                                            <Link to={`/bizs/${locationParam}`}>{locationParam}</Link>
                                                        </li>
                                                    )}
                                                    {category && (
                                                        <li className="breadcrumb-item">
                                                            <Link to={`/bizs/${locationParam}/${query}`}>{category}</Link>
                                                        </li>
                                                    )}
                                                    <li className="breadcrumb-item active" aria-current="page">
                                                        Search Results
                                                    </li>
                                                </ol>
                                            </nav>
                                            <div>
                                                <h1 className="ft-bold">
                                                    The Best {query || 'Businesses'} {locationParam ? `in ${locationParam}` : ''}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <div className="d-block grouping-listings">
                                                <div className="d-block grouping-listings-title">
                                                    <h5 className="ft-medium mb-3">Sponsored Results</h5>
                                                </div>
                                                {/* Dynamic Business Listings */}
                                                {businesses.map(($values, index) => (
                                                    <div key={index} className="grouping-listings-single">
                                                        <div className="vrt-list-wrap">
                                                            <div className="vrt-list-wrap-head">
                                                                <div className="vrt-list-thumb">
                                                                    <div className="vrt-list-thumb-figure">
                                                                        <img src={`https://localbizs.s3.eu-north-1.amazonaws.com${$values.image1_URL}`} className="img-fluid" alt={$values.image1_Title} />                                                            </div>
                                                                </div>
                                                                <div className="vrt-list-content">

                                                                    <h4 className="mb-0 ft-medium">
                                                                        <a href={generateSEOUrl($values.cityName, $values.name, $values.businessDetailID)} target="_blank" rel="noopener noreferrer" className="business-name-link" >
                                                                            {$values.businessName}
                                                                            <span className="verified-badge">
                                                                                <i className="fas fa-check-circle"></i>
                                                                            </span>
                                                                        </a>
                                                                    </h4>
                                                                    <div className="Goodup-ft-first">
                                                                        <div className="Goodup-rating">
                                                                            <div className="Goodup-rates">
                                                                                {Array.from({ length: 5 }, (_, i) => (
                                                                                    <i key={i} className={i < $values.rating ? "fas fa-star" : "far fa-star"}></i>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="Goodup-price-range">
                                                                            <span className="small ft-medium">{$values.reviewCount} Reviews</span>
                                                                            <div className="d-inline ms-2">
                                                                                {Array.from({ length: $values.priceLevel || 0 }, (_, i) => (
                                                                                    <span key={i} className="active"><i className="fas fa-dollar-sign"></i></span>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vrt-list-features mt-2 mb-2">
                                                                        <ul>
                                                                            {$values.specialties.split(',').map((specialty, index) => (
                                                                                <li key={index}>
                                                                                    <a href="javascript:void(0);">{specialty.trim()}</a>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="vrt-list-sts">
                                                                        <h5 className="ft-bold mb-2">Working Hours:</h5>
                                                                        <ul className="working-hours-list">
                                                                            {$values.workingHours.split('\n').map((hours, index) => {
                                                                                const [day, time] = hours.split(':').map(item => item.trim());
                                                                                const isClosed = time && time.includes('Closed'); // Updated line

                                                                                return (
                                                                                    <li key={index} className="working-hours-item">
                                                                                        <span className="day-time">
                                                                                            <i className={`fas ${isClosed ? 'fa-times-circle' : 'fa-clock'} status-icon`}
                                                                                                style={{ color: isClosed ? 'red' : 'green' }}></i>
                                                                                            {day}
                                                                                        </span>
                                                                                        <span className={`time-range ${isClosed ? 'closed-status' : 'open-status'}`}>
                                                                                            {isClosed ? 'Closed' : time}
                                                                                        </span>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="vrt-list-features mt-2 mb-2">
                                                                        {/* <p className="vrt-qgunke">{$values.description}</p> */}
                                                                        <ReadMore text={$values.description} />
                                                                    </div>
                                                                    <div className="vrt-list-amenties">
                                                                        <ul>
                                                                            {$values.insuranceAccepted.split(',').map((insurance, index) => (
                                                                                <li key={index}>
                                                                                    <div className="vrt-amens yes">
                                                                                        <span>{insurance.trim()}</span>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                    {/* Address Section */}
                                                                    <address className="mt-2 mb-2">
                                                                        {$values.address.replace(/\nDirections/, '')}
                                                                    </address>

                                                                    {/* Additional Information */}
                                                                    <div className="business-details">
                                                                        <p><strong>Phone:</strong> {$values.phoneNumber}</p>
                                                                        <p><strong>Email:</strong> {$values.email.split('|')[0]}</p>
                                                                        <p><strong>Website:</strong>
                                                                            <a href={$values.websiteURL.split('|')[1]} target="_blank" rel="noopener noreferrer">
                                                                                {$values.websiteURL.split('|')[0]}
                                                                            </a>
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* Pagination Section */}
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link" href="#" aria-label="Previous" onClick={() => currentPage > 1 && paginate(currentPage - 1)}>
                                                                <span className="fas fa-arrow-circle-left"></span>
                                                                <span className="sr-only">Previous</span>
                                                            </a>
                                                        </li>
                                                        {pageNumbers.map(number => (
                                                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                                                <a className="page-link" href="#" onClick={() => paginate(number)}>
                                                                    {number}
                                                                </a>
                                                            </li>
                                                        ))}
                                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                            <a className="page-link" href="#" aria-label="Next" onClick={() => currentPage < totalPages && paginate(currentPage + 1)}>
                                                                <span className="fas fa-arrow-circle-right"></span>
                                                                <span className="sr-only">Next</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <p className="text-center">Page {currentPage} of {totalPages}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* Row */}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}

        </div>
    );
};
export default BusinessListing;