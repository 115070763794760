import React from 'react';

// Sample review data for various sectors
const reviewsData = {
    'Best Lawyers': [
        {
            id: 1,
            name: "James T. Parker",
            date: "15 Oct 2023",
            location: "New York, USA",
            rating: 5,
            comment: "I had a great experience working with this law firm. They were highly professional, understanding, and quick to resolve my case. They kept me updated every step of the way and made sure I felt supported throughout the process.",
            imageUrl: "/assets/img/avatar.png",
            likes: 20,
            dislikes: 2,
            loves: 12,
        },
        {
            id: 2,
            name: "Sarah L. Green",
            date: "20 Oct 2023",
            location: "Los Angeles, USA",
            rating: 4,
            comment: "The lawyers were very knowledgeable and helped me navigate a complicated legal situation. The only issue was the response time, but once they were on it, everything moved quickly. I would definitely recommend their services.",
            imageUrl: "/assets/img/avatar.png",
            likes: 15,
            dislikes: 3,
            loves: 10,
        },
        {
            id: 3,
            name: "Michael P. Turner",
            date: "25 Oct 2023",
            location: "Chicago, USA",
            rating: 5,
            comment: "I was impressed with the professionalism and expertise of this law firm. They handled my case with care and kept me informed throughout the entire process. I felt confident in their abilities and would highly recommend them.",
            imageUrl: "/assets/img/avatar.png",
            likes: 22,
            dislikes: 1,
            loves: 18,
        },
    ],
    'Best Restaurants': [
        {
            id: 1,
            name: "Olivia M. Johnson",
            date: "18 Oct 2023",
            location: "New York, USA",
            rating: 5,
            comment: "The food was absolutely phenomenal! The grilled chicken was tender and flavorful, and the desserts were the perfect ending to a fantastic meal. The staff were friendly, and the ambiance was perfect for a romantic dinner.",
            imageUrl: "/assets/img/avatar.png",
            likes: 45,
            dislikes: 2,
            loves: 30,
        },
        {
            id: 2,
            name: "John S. Bailey",
            date: "22 Oct 2023",
            location: "San Francisco, USA",
            rating: 4,
            comment: "The atmosphere was amazing, and the food was very good. However, the service was a bit slow during peak hours. Overall, a great dining experience, and I will be back for sure.",
            imageUrl: "/assets/img/avatar.png",
            likes: 30,
            dislikes: 5,
            loves: 18,
        },
        {
            id: 3,
            name: "Emma R. Martinez",
            date: "28 Oct 2023",
            location: "Miami, USA",
            rating: 5,
            comment: "An outstanding dining experience! The food was fresh and delicious, and the service was impeccable. This is definitely one of the best restaurants I've been to, and I highly recommend it.",
            imageUrl: "/assets/img/avatar.png",
            likes: 50,
            dislikes: 3,
            loves: 40,
        },
    ],
    'Best Doctors': [
        {
            id: 1,
            name: "John D. Adams",
            date: "12 Oct 2023",
            location: "Boston, USA",
            rating: 5,
            comment: "The doctor provided excellent care during my visit. They took the time to listen to all my concerns and made me feel comfortable throughout the entire consultation. Their expertise and clear explanations really helped me understand my condition, and I felt confident in the recommendations. Highly recommend this clinic to anyone seeking compassionate and skilled healthcare!",
            imageUrl: "/assets/img/avatar.png",
            likes: 18,
            dislikes: 0,
            loves: 12,
        },
        {
            id: 2,
            name: "Grace H. Evans",
            date: "16 Oct 2023",
            location: "Dallas, USA",
            rating: 4,
            comment: "The doctor was very thorough and took the time to explain everything to me in detail. The only issue was the wait time, but once I was seen, the appointment went smoothly. I would recommend this clinic to anyone looking for quality healthcare.",
            imageUrl: "/assets/img/avatar.png",
            likes: 12,
            dislikes: 1,
            loves: 8,
        },
        {
            id: 3,
            name: "Aiden T. Clark",
            date: "20 Oct 2023",
            location: "Chicago, USA",
            rating: 5,
            comment: "Exceptional care! The doctor was kind, patient, and knowledgeable. They took the time to address all my concerns, and I left feeling confident in the treatment plan. I highly recommend this practice.",
            imageUrl: "/assets/img/avatar.png",
            likes: 22,
            dislikes: 0,
            loves: 16,
        },
    ],
    // Add reviews for other sectors in a similar fashion
    // Example for 'Best Entertainment', 'Meilleurs Avocats', etc.
};

// Review Item Component
const ReviewItem = ({ review }) => {
    const { name, date, location, rating, comment, imageUrl, likes, dislikes, loves } = review;

    return (
        <div className="reviews-comments-item" key={review.id}>
            <div className="review-comments-avatar">
                <img src={imageUrl} className="img-fluid" alt={name} />
            </div>
            <div className="reviews-comments-item-text">
                <h4>
                    <a href="javascript:void(0)">{name}</a>
                    <span className="reviews-comments-item-date">
                        <i className="ti-calendar theme-cl me-1"></i>{date}
                    </span>
                </h4>
                <span className="agd-location">
                    <i className="lni lni-map-marker me-1"></i>{location}
                </span>
                <div className="listing-rating">
                    {[...Array(5)].map((_, i) => (
                        <i key={i} className={`fas fa-star ${i < rating ? 'active' : ''}`}></i>
                    ))}
                </div>
                <div className="clearfix"></div>
                <p>{`"${comment}"`}</p>
                <div className="pull-left reviews-reaction">
                    <a href="javascript:void(0)" className="comment-like active"><i className="ti-thumb-up"></i> {likes}</a>
                    <a href="javascript:void(0)" className="comment-dislike active"><i className="ti-thumb-down"></i> {dislikes}</a>
                    <a href="javascript:void(0)" className="comment-love active"><i className="ti-heart"></i> {loves}</a>
                </div>
            </div>
        </div>
    );
};

// Reviews Section Component
const ReviewsSection = ({ sectorName }) => {
    // Dynamically choose reviews based on sectorName
    const selectedReviews = reviewsData[sectorName] || []; // Default to an empty array if no valid sector

    return (
        <div className="reviews-comments-wrap">
            {selectedReviews.map((review) => (
                <ReviewItem review={review} key={review.id} />
            ))}
            <ul className="pagination">
                <li className="page-item">
                    <a className="page-link" href="javascript:void(0)" aria-label="Previous">
                        <span className="fas fa-arrow-circle-left"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>
                {/* Add pagination items dynamically as needed */}
                <li className="page-item active"><a className="page-link" href="javascript:void(0)">1</a></li>
                <li className="page-item"><a className="page-link" href="javascript:void(0)">2</a></li>
                <li className="page-item"><a className="page-link" href="javascript:void(0)">3</a></li>
                <li className="page-item">
                    <a className="page-link" href="javascript:void(0)" aria-label="Next">
                        <span className="fas fa-arrow-circle-right"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

// Example usage
const ReviewsList = ({ sectorName }) => {
    return (
        <div className="d-block">
            <div className="jbd-01">
                <div className="jbd-details mb-4">
                    <h5 className="ft-bold fs-lg">Recommended Reviews</h5>
                    <ReviewsSection sectorName={sectorName} />
                </div>
            </div>
        </div>
    );
};

export default ReviewsList;