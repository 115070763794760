import React from 'react';

const CategoryPage = ({ 
    backgroundUrl = '', 
    overlay = '0.5', 
    title = 'Explore Categories', 
    description = 'Discover the best services near you.', 
    categories = [], 
    location = 'Default Location' 
}) => {
    return (
        <>
            <section className="category-page-container">
                {/* Banner Section */}
                {/* <div
                    className="category-banner"
                    style={{
                        backgroundImage: `url(${backgroundUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative',
                    }}
                >
                    <div
                        className="banner-overlay"
                        style={{
                            backgroundColor: `rgba(0, 0, 0, ${overlay})`,
                        }}
                    ></div>
                    <div className="banner-content">
                        <h1 className="banner-title">{title}</h1>
                        <p className="banner-description">{description}</p>
                    </div>
                </div> */}

                {/* Categories Section */}
                <div className="categories-section container">
                    <div className="row">
                        {categories && categories.length > 0 ? (
                            categories.map((category, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-12 category-item" key={index}>
                                    <a
                                        href={`categories/${category.link}/${encodeURIComponent(location || 'Default Location')}`}
                                        className="category-box"
                                    >
                                        <div className="category-icon">
                                            <i className={category.icon}></i>
                                        </div>
                                        <div className="category-details">
                                            <h5 className="category-name">{category.name}</h5>
                                        </div>
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p className="no-categories">No categories found.</p>
                        )}
                    </div>
                </div>
            </section>

            {/* Styles */}
            <style jsx>{`
                .category-page-container {
                    font-family: 'Arial', sans-serif;
                }

                /* Banner Section */
                .category-banner {
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                }

                .banner-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .banner-content {
                    position: relative;
                    text-align: center;
                    z-index: 2;
                }

                .banner-title {
                    font-size: 36px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .banner-description {
                    font-size: 18px;
                }

                /* Categories Section */
                .categories-section {
                    padding: 40px 20px;
                }

                .category-item {
                    margin-bottom: 20px;
                }

                .category-box {
                    display: block;
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                    text-align: center;
                    padding: 20px;
                    transition: all 0.3s ease;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }

                .category-box:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
                }

                .category-icon {
                    font-size: 40px;
                    color: #f41b3b;
                    margin-bottom: 10px;
                }

                .category-name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #333;
                }

                .no-categories {
                    text-align: center;
                    font-size: 18px;
                    color: #555;
                }

                /* Responsive Design */
                @media (max-width: 768px) {
                    .category-banner {
                        height: 200px;
                    }

                    .banner-title {
                        font-size: 28px;
                    }

                    .banner-description {
                        font-size: 16px;
                    }
                }
            `}</style>
        </>
    );
};

export default CategoryPage;